<template>
    <form-table hasHead hasFoot @cancel="cancel" @submit="submit">
        <form-table-row label="아이디" isRequired>
            <v-text-field v-model="user.username" :rules="usernameRules" @input="input" type="text" outlined class="mt-n4 mb-n18" />
        </form-table-row>
        <form-table-row label="비밀번호" isRequired>
            <v-text-field v-model="user.password" @input="input" v-bind="{ rules }" counter type="password" name="password" outlined class="mt-n4 mb-n18">
                <span slot="counter" class="font-size-12 error--text" style="height: 12px; line-height: 12px">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자) </span>
            </v-text-field>
        </form-table-row>
        <form-table-row label="비밀번호 확인" isRequired>
            <v-text-field v-model="user.passwordConfirm" @input="input" type="password" name="password2" outlined hide-details />
        </form-table-row>
        <form-table-row label="이름" isRequired>
            <v-text-field v-model="user.name" @input="input" type="text" name="name" outlined hide-details />
        </form-table-row>
        <form-table-row label="성별" isRequired>
            <v-radio-group v-model="user.gender" row hide-details @input="input">
                <v-radio label="여성" value="여성" />
                <v-radio label="남성" value="남성" />
                <!-- <v-radio label="선택하지 않음" :value="null" /> -->
            </v-radio-group>
        </form-table-row>
        <form-table-row label="생년월일" isRequired>
            <v-date-select v-model="user.birthday" outlined hide-details />
        </form-table-row>
        <form-table-row label="연락처" isRequired>
            <v-phone-field v-model="user" @input="input" outlined hide-details ref="phone-input" />
        </form-table-row>
        <form-table-row label="이메일" isRequired>
            <v-email-field v-model="user.email" @input="input" outlined hide-details />
        </form-table-row>
        <form-table-row label="주소" isRequired>
            <v-address-field v-model="user" @input="input" outlined hide-details />
        </form-table-row>
        <form-table-row label="관심키워드">
            <autocomplete-program-keywords v-model="user._keywords" @input="input" outlined hide-details />
        </form-table-row>
    </form-table>
</template>

<script>
import FormTable from "@/components/client/dumb/form-table.vue";
import VDateSelect from "@/components/client/dumb/v-date-select.vue";
import VPhoneField from "@/components/client/dumb/v-phone-field.vue";
import VEmailField from "@/components/client/dumb/v-email-field.vue";
import FormTableRow from "@/components/client/dumb/form-table-row.vue";
import VAddressField from "@/components/client/dumb/v-address-field.vue";
import AutocompleteProgramKeywords from "@/components/dumb/autocomplete-program-keywords.vue";

let rules = [(value) => /[~!@#$%^&*()_+|<>?:{}]/.test(value) || "비밀번호에 특수문자를 넣어주세요", (value) => /[a-zA-Z]/.test(value) || "비밀번호에 영문을 넣어주세요", (value) => /[0-9]/.test(value) || "비밀번호에 숫자를 넣어주세요", (value) => (value && 8 <= value.length && value.length <= 16) || "비밀번호를 8 ~ 16자 사이로 작성해주세요"];

export default {
    components: {
        FormTable,
        VDateSelect,
        VPhoneField,
        VEmailField,
        FormTableRow,
        VAddressField,
        AutocompleteProgramKeywords,
    },
    props: ["value"],
    data() {
        return {
            rules,
            usernameRules: [(value) => !/[^a-z0-9]/.test(value) || "아이디는 영문소문자 및 숫자로만 작성하실 수 있습니다"],
            user: this.$props.value || {},
        };
    },
    methods: {
        input() {
            this.$emit("input", this.user);
        },
        cancel() {
            this.$router.go(-1);
        },

        submit() {
            if (this.validates()) this.$emit("submit", this.user);
        },

        validates() {
            try {
                if (!this.user.username) throw new Error("아이디를 입력해주세요.");
                if (/[^a-z0-9]/.test(this.user.username)) throw new Error("아이디는 영문소문자 및 숫자로만 작성하실 수 있습니다");
                if (!this.user.password) throw new Error("비밀번호를 입력해주세요.");
                if (!this.checkPassword(this.user.password)) return false;
                if (!this.user.passwordConfirm) throw new Error("비밀번호 확인을 입력해주세요.");
                if (this.user.password != this.user.passwordConfirm) throw new Error("비밀번호를 확인해주세요");
                if (!this.user.name) throw new Error("이름을 입력해주세요.");
                if (!this.user.gender && this.user.gender != null) throw new Error("성별을 입력해주세요.");
                if (!this.user.birthday) throw new Error("생년월일을 입력해주세요.");
                if (!this.user.phone) throw new Error("연락처를 입력해주세요.");
                if (!this.user.email) throw new Error("이메일을 입력해주세요.");
                if (!this.user.address1) throw new Error("주소를 입력해주세요.");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");

            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");

            return true;
        },
    },
};
</script>
