var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-table', {
    attrs: {
      "hasHead": "",
      "hasFoot": ""
    },
    on: {
      "cancel": _vm.cancel,
      "submit": _vm.submit
    }
  }, [_c('form-table-row', {
    attrs: {
      "label": "아이디",
      "isRequired": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mt-n4 mb-n18",
    attrs: {
      "rules": _vm.usernameRules,
      "type": "text",
      "outlined": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "비밀번호",
      "isRequired": ""
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "mt-n4 mb-n18",
    attrs: {
      "counter": "",
      "type": "password",
      "name": "password",
      "outlined": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  }, 'v-text-field', {
    rules: _vm.rules
  }, false), [_c('span', {
    staticClass: "font-size-12 error--text",
    staticStyle: {
      "height": "12px",
      "line-height": "12px"
    },
    attrs: {
      "slot": "counter"
    },
    slot: "counter"
  }, [_vm._v("(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자) ")])])], 1), _c('form-table-row', {
    attrs: {
      "label": "비밀번호 확인",
      "isRequired": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "password2",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.user, "passwordConfirm", $$v);
      },
      expression: "user.passwordConfirm"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "이름",
      "isRequired": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "name",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "성별",
      "isRequired": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.gender,
      callback: function ($$v) {
        _vm.$set(_vm.user, "gender", $$v);
      },
      expression: "user.gender"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "여성",
      "value": "여성"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "남성",
      "value": "남성"
    }
  })], 1)], 1), _c('form-table-row', {
    attrs: {
      "label": "생년월일",
      "isRequired": ""
    }
  }, [_c('v-date-select', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.birthday,
      callback: function ($$v) {
        _vm.$set(_vm.user, "birthday", $$v);
      },
      expression: "user.birthday"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "연락처",
      "isRequired": ""
    }
  }, [_c('v-phone-field', {
    ref: "phone-input",
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "이메일",
      "isRequired": ""
    }
  }, [_c('v-email-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "주소",
      "isRequired": ""
    }
  }, [_c('v-address-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "관심키워드"
    }
  }, [_c('autocomplete-program-keywords', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user._keywords,
      callback: function ($$v) {
        _vm.$set(_vm.user, "_keywords", $$v);
      },
      expression: "user._keywords"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }