<template>
    <div>
        <div class="mb-6" v-if="hasHead">
            <p class="font-size-14 text-right"><span style="color: rgb(255, 0, 0);">*</span> 필수입력입니다</p>
        </div>
        <div class="table-style table-style--default">
            <slot />
        </div>

        <!-- 하단버튼 -->
        <div v-if="hasFoot" class="v-btn-group--bottom">
            <v-row justify="center" class="row--small">
                <v-col cols="6" sm="auto">
                    <v-btn large outlined block color="grey-d6" class="w-md-240px" @click="$emit('cancel')">취소</v-btn>
                </v-col>
                <v-col cols="6" sm="auto">
                    <v-btn large block color="primary" class="w-md-240px" @click="$emit('submit')">회원가입</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hasHead: { type: Boolean, default: false },
        hasFoot: { type: Boolean, default: false },
    },
};
</script>

<style></style>
