var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.hasHead ? _c('div', {
    staticClass: "mb-6"
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_vm._t("default")], 2), _vm.hasFoot ? _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "outlined": "",
      "block": "",
      "color": "grey-d6"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('submit');
      }
    }
  }, [_vm._v("회원가입")])], 1)], 1)], 1) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "font-size-14 text-right"
  }, [_c('span', {
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력입니다")]);

}]

export { render, staticRenderFns }